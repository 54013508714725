import moment, { Moment } from 'moment';
import 'moment-timezone';
import { IReportDashboard, IReportGraph } from './interfaces.ts';
import {
  CERTIFICATES_ISSUED_MENU,
  REPORT_CATEGORY,
  REPORT_TERM,
} from './constants.ts';
import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { NavigateFunction } from 'react-router-dom';
import { isMacOs } from 'react-device-detect';
import { OptionValue } from '@src/components/Select.tsx';

export function getFirstCharacter(input: string | undefined): string {
  const specialCharacters = ['㈜', '(주)', '(유)', '주식회사 '];

  if (input === undefined) {
    return '정';
  }

  for (const specialCharacter of specialCharacters) {
    if (input.startsWith(specialCharacter)) {
      return input.charAt(specialCharacter.length);
    }
  }

  return input.charAt(0);
}

export const phoneRegex = new RegExp(
  /^01([0|16789])-?([0-9]{3,4})-?([0-9]{4})$/,
);
export const numericRegex = new RegExp(/^\d+$/);
export const companyRegisterNumberRegex = new RegExp(
  /([0-9]{3})-?([0-9]{2})-?([0-9]{5})/,
);

export const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);

export const passwordRegex = new RegExp(
  /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]{8,15}$/,
);

export function getLastSixMonths(today: moment.Moment, format: string) {
  const dates = [];

  for (let i = 5; i >= 0; i--) {
    const date = moment(today).subtract(i, 'months').startOf('month');
    dates.push(date.format(format));
  }

  return dates;
}

export function getLastSixDays(today: moment.Moment, format: string) {
  const dates = [];

  for (let i = 5; i >= 0; i--) {
    const date = moment(today).subtract(i, 'days');
    dates.push(date.format(format));
  }

  return dates;
}

export function getFirstDayOfMonth(date: Moment): string {
  // Clone the Moment object to avoid changing the original date
  return date.clone().startOf('month').format('YYYYMMDD');
}

export function getLastDayOfMonth(date: Moment): string {
  // Clone the Moment object to avoid changing the original date
  return date.clone().endOf('month').format('YYYYMMDD');
}

export function getFirstDayOfFirstMonth(date: Moment): string {
  return date.clone().startOf('year').format('YYYYMMDD');
}

export function getLastDayOfLastMonth(date: Moment): string {
  return date.clone().endOf('year').format('YYYYMMDD');
}

export function getFirstDayOfFifthPreviousMonth(date: Moment): string {
  return date.clone().subtract(5, 'months').startOf('month').format('YYYYMMDD');
}

export function getFirstDayOfFifthPreviousDays(date: Moment): string {
  return date.clone().subtract(5, 'days').format('YYYYMMDD');
}

export function getFirstDayOfLastYear(date: Moment): string {
  return date.clone().subtract(1, 'years').startOf('year').format('YYYYMMDD');
}

export function processData(
  data: IReportDashboard | undefined,
  isTaxable: boolean,
) {
  if (data) {
    const salesResult = processSales(data['data']['sales'], isTaxable);
    const purchaseResult = processPurchase(data['data']['purchase'], isTaxable);

    const laborResult = processLabor(data['data']['labor']);
    const totalResult =
      salesResult.totalNumber -
      purchaseResult.totalNumber -
      laborResult.totalNumber;

    return {
      sales: salesResult,
      purchase: purchaseResult,
      total: totalResult.toLocaleString(),
      labor: laborResult,
    };
  }
}

export function processSales(
  data: IReportDashboard['data']['sales'],
  isTaxable: boolean,
) {
  const statementAmount = isTaxable
    ? data.statement.sum.sup_amount
    : data.statement.sum.tot_amount;
  const cardAmount = isTaxable
    ? data.card.sum.sup_amount
    : data.card.sum.tot_amount;
  const cashAmount = isTaxable
    ? data.cash.sum.sup_amount
    : data.cash.sum.tot_amount;
  const commerceAmount =
    (isTaxable
      ? data.commerce?.sum.sup_amount
      : data.commerce?.sum.tot_amount) ?? 0;
  const deliveryAmount =
    (isTaxable
      ? data.delivery?.sum.sup_amount
      : data.delivery?.sum.tot_amount) ?? 0;
  const commerceAndDelivery = commerceAmount + deliveryAmount;

  const totalAmount =
    statementAmount + cardAmount + cashAmount + commerceAndDelivery;
  return {
    statement: statementAmount.toLocaleString(),
    card: cardAmount.toLocaleString(),
    cash: cashAmount.toLocaleString(),
    commerceAndDelivery: commerceAndDelivery.toLocaleString(),
    total: totalAmount.toLocaleString(),
    totalNumber: totalAmount,
  };
}

export function processPurchase(
  data: IReportDashboard['data']['purchase'],
  isTaxable: boolean,
) {
  const statementAmount = isTaxable
    ? data.statement.sum.sup_amount
    : data.statement.sum.tot_amount;
  const cardAmount = isTaxable
    ? data.card.sum.sup_amount
    : data.card.sum.tot_amount;
  const cashAmount = isTaxable
    ? data.cash.sum.sup_amount
    : data.cash.sum.tot_amount;
  const receiptAmount =
    (isTaxable ? data.receipt?.sum.sup_amount : data.receipt?.sum.tot_amount) ??
    0;

  const totalAmount = statementAmount + cardAmount + cashAmount + receiptAmount;
  return {
    statement: statementAmount.toLocaleString(),
    card: cardAmount.toLocaleString(),
    cash: cashAmount.toLocaleString(),
    receipt: receiptAmount.toLocaleString(),
    total: totalAmount.toLocaleString(),
    totalNumber: totalAmount,
  };
}

export function processLabor(data: IReportDashboard['data']['labor']) {
  if (data) {
    const { employee, etc, freelancer, severance } = data;
    const employee_amount = employee.sum.sup_amount;
    const freelancer_amount = freelancer.sum.sup_amount;
    const severance_amount = severance.sum.sup_amount;
    const etc_amount = etc.sum.sup_amount;
    const totalNumber =
      employee_amount + freelancer_amount + severance_amount + etc_amount;
    return {
      employee_amount: employee_amount.toLocaleString(),
      freelancer_amount: freelancer_amount.toLocaleString(),
      severance_amount: severance_amount.toLocaleString(),
      etc_amount: etc_amount.toLocaleString(),
      total: totalNumber.toLocaleString(),
      totalNumber: totalNumber,
    };
  } else {
    return {
      employee_amount: '0',
      freelancer_amount: '0',
      severance_amount: '0',
      etc_amount: '0',
      total: '0',
      totalNumber: 0,
    };
  }
}

export const loadScript = (
  src: string,
  integrity: string,
  crossOrigin: string,
  callback: () => void,
) => {
  const script = document.createElement('script');
  script.src = src;
  script.integrity = integrity;
  script.crossOrigin = crossOrigin;
  script.onload = callback;
  document.head.appendChild(script);
};

export const fileDownload = (url: string) => {
  let iframe = document.getElementById(
    'downloadIframe',
  ) as HTMLIFrameElement | null;
  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.id = 'downloadIframe';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
  }
  iframe.src = url;
};

export const fnNxSetupCheck = async (
  data: Record<string, string>,
): Promise<boolean> => {
  const axiosConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
  };
  return await axios
    .post('https://127.0.0.1:16566/?op=setup', data, axiosConfig)
    .then((response) => {
      console.log(response);
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
      // fnNxDownload("https://www.infotech.co.kr/ExAdapter_Web_Setup_20221130.exe");
      // if (navigator.userAgent.includes("Firefox")) {
      //   alert("설치가 완료된 후에 브라우저를 닫고, 다시 시작하여 이용하시기 바랍니다.(Firefox browser)");
      // }
    });
};

export const fnNxCertList = async (data: Record<string, string>) => {
  try {
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    };
    return await axios.post(
      'https://127.0.0.1:16566/?op=certList',
      data,
      axiosConfig,
    );
  } catch (error) {
    console.log(error);
    // if (navigator.userAgent.includes("Firefox")) {
    //   alert("설치가 완료된 후에 브라우저를 닫고, 다시 시작하여 이용하시기 바랍니다.(Firefox browser)");
    // }
  }
};

export const fnNxCertResult = async (selectedCert: Record<string, string>) => {
  selectedCert.orgCd = 'common';
  selectedCert.svcCd = 'getCertInfo';
  selectedCert.appCd = 'InfotechApiDemo';

  if (isMacOs) {
    selectedCert.signCert = selectedCert.path + '/signCert.der';
    selectedCert.signPri = selectedCert.path + '/signPri.key';
  } else {
    selectedCert.signCert = selectedCert.path + '\\signCert.der';
    selectedCert.signPri = selectedCert.path + '\\signPri.key';
  }

  try {
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
    };

    return await axios.post(
      'https://127.0.0.1:16566/?op=execute',
      selectedCert,
      axiosConfig,
    );
  } catch (error) {
    console.log(error);
    // if (navigator.userAgent.includes("Firefox")) {
    //   alert("설치가 완료된 후에 브라우저를 닫고, 다시 시작하여 이용하시기 바랍니다.(Firefox browser)");
    // }
  }
};

export const processGraphData = (
  today: Moment,
  data: IReportGraph,
  termType: string,
  transactionType: string,
) => {
  const { purchase, sales } = data.data;
  const target: number[] = [];

  if (termType === REPORT_TERM.MONTHLY || REPORT_TERM.DAILY) {
    const xAxisData =
      termType === REPORT_TERM.MONTHLY
        ? getLastSixMonths(today, 'YYYYMM')
        : getLastSixDays(today, 'YYYYMMDD');

    if (transactionType === REPORT_CATEGORY.SALES) {
      xAxisData.forEach((xData) => {
        target.push(sales?.sup_amount?.[xData] ?? 0);
      });
    } else if (transactionType === REPORT_CATEGORY.PURCHASE) {
      xAxisData.forEach((xData) => {
        target.push(purchase?.sup_amount?.[xData] ?? 0);
      });
    } else {
      xAxisData.forEach((xData) => {
        // 하나만 있거나, 둘다 있거나, 없는 경우
        const purchaseValue = purchase?.sup_amount?.[xData] ?? 0;
        const salesValue = sales?.sup_amount?.[xData] ?? 0;
        target.push(salesValue - purchaseValue);
      });
    }
  }

  return target;
};

export const getDateInfo = (activeHeaderTab: string, today: Moment) => {
  if (activeHeaderTab === REPORT_TERM.MONTHLY) {
    return `${today.format('MM')}월`;
  } else if (activeHeaderTab === REPORT_TERM.DAILY) {
    return `${today.format('MM')}월 ${today.format('DD')}일`;
  } else {
    return `${today.format('YYYY')}년`;
  }
};

export const getDateFullInfo = (date: Moment, term: string) => {
  let dateStr = `${date.format('YYYY')}년`;

  if (term === REPORT_TERM.MONTHLY) {
    dateStr = dateStr.concat(` ${date.format('MM')}월`);
  } else if (term === REPORT_TERM.DAILY) {
    dateStr = dateStr.concat(` ${date.format('MM')}월 ${date.format('DD')}일`);
  }

  return dateStr;
};

export const getYearlyGraphUnits = (value: number) => {
  return Math.floor(value / 10000).toLocaleString();
};

export const isStringANumber = (value: string): boolean => {
  const num = parseFloat(value);
  return !isNaN(num) && isFinite(num);
};

export const getPreviousDate = (date: Moment, term: string) => {
  switch (term) {
    case REPORT_TERM.DAILY:
      return date.clone().subtract(1, 'days');
    case REPORT_TERM.MONTHLY:
      return date.clone().subtract(1, 'months');
    case REPORT_TERM.YEARLY:
      return date.clone().subtract(1, 'years');
    default:
      throw new Error('Invalid term provided');
  }
};

export const getNextDate = (date: Moment, term: string) => {
  switch (term) {
    case REPORT_TERM.DAILY:
      return date.clone().add(1, 'days');
    case REPORT_TERM.MONTHLY:
      return date.clone().add(1, 'months');
    case REPORT_TERM.YEARLY:
      return date.clone().add(1, 'years');
    default:
      throw new Error('Invalid term provided');
  }
};

export const formatDateString = (dateStr: string) => {
  let formattedDate;
  if (dateStr === '-') {
    return dateStr;
  }

  // Check the length of the input to determine its format
  if (dateStr.length === 8) {
    // YYYYMMDD format
    formattedDate = moment(dateStr, 'YYYYMMDD').format('YYYY. MM. DD');
  } else if (dateStr.length === 6) {
    // YYYYMM format
    formattedDate = moment(dateStr, 'YYYYMM').format('YYYY. MM');
  } else {
    if (dateStr.trim().length !== 0) {
      formattedDate = moment(dateStr.substring(0, 10), 'YYYY-MM-DD').format(
        'YYYY. MM. DD',
      );
    } else {
      // Handle invalid input
      formattedDate = '';
    }
  }

  return formattedDate;
};

export function convertToKST(utcTimestamp: string): string {
  // Convert the UTC timestamp to KST
  const kstDate = moment.utc(utcTimestamp).tz('Asia/Seoul');

  // Format the date to YYYY-MM-DD
  return kstDate.format('YYYY. MM. DD');
}

export function formatPhoneNumber(phoneNumber: string | undefined) {
  if (phoneNumber) {
    const digits = phoneNumber.replace(/\D/g, '');
    return digits.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else {
    return '';
  }
}

export function objectToQueryString(obj: {
  [key: string]: string | number | boolean | (string | number | boolean)[];
}): string {
  const keyValuePairs: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) =>
        keyValuePairs.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(item.toString())}`,
        ),
      );
    } else {
      keyValuePairs.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`,
      );
    }
  });
  return keyValuePairs.join('&');
}

export const downloadPdfUsingFetch = async (
  fileUrl: string,
  fileName: string,
): Promise<void> => {
  try {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch PDF: ${response.statusText}`);
    }

    const blob = await response.blob();

    const blobUrl = window.URL.createObjectURL(blob);

    const anchorElement = document.createElement('a');
    anchorElement.href = blobUrl;
    anchorElement.download = fileName;
    document.body.appendChild(anchorElement);
    anchorElement.click();

    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

export const findVatPeriod = (status: boolean) => {
  if (!status) {
    const lastStartOfJuly = moment()
      .subtract(1, 'year')
      .month(6) // 7월
      .startOf('month'); // 작년 7월 1일
    const lastEndOfYear = moment().subtract(1, 'year').endOf('year'); // 작년 12월 31일
    const today = moment(); // 현재일
    const startOfYear = moment().startOf('year'); // 1월 1일
    const january25th = moment().startOf('year').date(25); // 1월 25일
    const january26th = moment().startOf('year').date(26); // 1월 26일
    const endOfJune = moment().startOf('year').month(5).endOf('month'); // 6월 30일
    const startOfJuly = moment().startOf('year').month(6).startOf('month'); // 7월 1일
    const july25th = moment().startOf('year').month(6).date(25); // 7월 25일
    const july26th = moment().startOf('year').month(6).date(26); // 7월 26일
    const nextJanuary25th = moment().startOf('year').add(1, 'year').date(25); // 다음 해 1월 25일

    if (today.isBefore(january26th)) {
      // 1월 1일부터 1월 25일까지
      return {
        vat_start_date: lastStartOfJuly.format('YYYYMMDD'),
        vat_end_date: lastEndOfYear.format('YYYYMMDD'),
        vat_expected_date: january25th.format('YYYYMMDD'),
      };
    } else if (today.isBefore(startOfJuly)) {
      // 1월 26일부터 6월 30일까지
      return {
        vat_start_date: startOfYear.format('YYYYMMDD'),
        vat_end_date: today.format('YYYYMMDD'),
        vat_expected_date: july25th.format('YYYYMMDD'),
      };
    } else if (today.isBefore(july26th)) {
      // console.log(`july25th: ${july26th.format('YYYY.MM.DD HH:mm:ss')}`)
      // console.log(`today: ${today.format('YYYY.MM.DD HH:mm:ss')}`)
      // console.log(today.isBefore(july26th))
      // 7월 1일부터 7월 25일까지
      return {
        vat_start_date: startOfYear.format('YYYYMMDD'),
        vat_end_date: endOfJune.format('YYYYMMDD'),
        vat_expected_date: july25th.format('YYYYMMDD'),
      };
    } else {
      // 7월 26일부터 12월 31일까지
      return {
        vat_start_date: startOfJuly.format('YYYYMMDD'),
        vat_end_date: today.format('YYYYMMDD'),
        vat_expected_date: nextJanuary25th.format('YYYYMMDD'),
      };
    }
  } else {
    const lastStartOfOctober = moment()
      .subtract(1, 'year')
      .month(9) //
      .startOf('month'); // 작년 10월 1일
    const lastEndOfYear = moment().subtract(1, 'year').endOf('year'); // 작년 12월 31일
    const today = moment(); // 현재일
    const startOfYear = moment().startOf('year'); // 1월 1일
    const january25th = moment().startOf('year').date(25); // 1월 25일
    const january26th = moment().startOf('year').date(26); // 1월 26일
    const endOfMarch = moment().startOf('year').month(2).endOf('month'); // 3월 31일
    const startOfApril = moment().startOf('year').month(3).startOf('month'); // 4월 1일
    const april25th = moment().startOf('year').month(3).date(25); // 4월 25일
    const april26th = moment().startOf('year').month(3).date(26); // 4월 26일
    const endOfJune = moment().startOf('year').month(5).endOf('month'); // 6월 30일
    const startOfJuly = moment().startOf('year').month(6).startOf('month'); // 7월 1일
    const july25th = moment().startOf('year').month(6).date(25); // 7월 25일
    const july26th = moment().startOf('year').month(6).date(26); // 7월 26일
    const endOfSeptember = moment().startOf('year').month(8).endOf('month'); // 9월 30일
    const startOfOctober = moment().startOf('year').month(9).startOf('month'); // 10월 1일
    const october25th = moment().startOf('year').month(9).date(25); // 10월 25일
    const october26th = moment().startOf('year').month(9).date(26); // 10월 26일
    const nextJanuary25th = moment().startOf('year').add(1, 'year').date(25); // 다음 해 1월 25일

    if (today.isBefore(january26th)) {
      // 1월 1일부터 1월 25일까지
      return {
        vat_start_date: lastStartOfOctober.format('YYYYMMDD'),
        vat_end_date: lastEndOfYear.format('YYYYMMDD'),
        vat_expected_date: january25th.format('YYYYMMDD'),
      };
    } else if (today.isBefore(startOfApril)) {
      // 1월 26일부터 3월 31일까지
      return {
        vat_start_date: startOfYear.format('YYYYMMDD'),
        vat_end_date: today.format('YYYYMMDD'),
        vat_expected_date: april25th.format('YYYYMMDD'),
      };
    } else if (today.isBefore(april26th)) {
      // 4월 1일부터 4월 25일까지
      return {
        vat_start_date: startOfYear.format('YYYYMMDD'),
        vat_end_date: endOfMarch.format('YYYYMMDD'),
        vat_expected_date: april25th.format('YYYYMMDD'),
      };
    } else if (today.isBefore(startOfJuly)) {
      // 4월 26일부터 6월 30일까지
      return {
        vat_start_date: startOfApril.format('YYYYMMDD'),
        vat_end_date: today.format('YYYYMMDD'),
        vat_expected_date: july25th.format('YYYYMMDD'),
      };
    } else if (today.isBefore(july26th)) {
      // 7월 1일부터 7월 25일까지
      return {
        vat_start_date: startOfApril.format('YYYYMMDD'),
        vat_end_date: endOfJune.format('YYYYMMDD'),
        vat_expected_date: july25th.format('YYYYMMDD'),
      };
    } else if (today.isBefore(startOfOctober)) {
      // 7월 26일부터 9월 30일까지
      return {
        vat_start_date: startOfJuly.format('YYYYMMDD'),
        vat_end_date: today.format('YYYYMMDD'),
        vat_expected_date: october25th.format('YYYYMMDD'),
      };
    } else if (today.isBefore(october26th)) {
      // 10월 1일부터 10월 25일까지
      return {
        vat_start_date: startOfJuly.format('YYYYMMDD'),
        vat_end_date: endOfSeptember.format('YYYYMMDD'),
        vat_expected_date: october25th.format('YYYYMMDD'),
      };
    } else {
      // 10월 26일부터 12월 31일까지
      return {
        vat_start_date: startOfOctober.format('YYYYMMDD'),
        vat_end_date: today.format('YYYYMMDD'),
        vat_expected_date: nextJanuary25th.format('YYYYMMDD'),
      };
    }
  }
};

export const findCorpTaxPeriod = (type: string) => {
  if (type == 'CORP') {
    const today = moment();
    const startOfYear = moment().startOf('year');
    const endOfMarch = moment().startOf('year').add(2, 'months').endOf('month');
    const startOfApril = moment()
      .startOf('year')
      .add(3, 'months')
      .startOf('month');

    if (today.isBefore(startOfApril)) {
      // 오늘 날짜가 3월 31일 이전이면, 작년 1월 1일부터 12월 31일까지를 반환
      return {
        corp_start_date: moment()
          .subtract(1, 'year')
          .startOf('year')
          .format('YYYYMMDD'),
        corp_end_date: moment()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYYMMDD'),
        corp_expected_date: endOfMarch.format('YYYYMMDD'),
      };
    } else {
      // 그렇지 않으면, 올해 1월 1일부터 오늘까지를 반환
      return {
        corp_start_date: startOfYear.format('YYYYMMDD'),
        corp_end_date: today.format('YYYYMMDD'),
        corp_expected_date: moment()
          .add(1, 'years')
          .month(3 - 1)
          .date(31)
          .format('YYYYMMDD'),
      };
    }
  } else {
    const today = moment();
    const startOfYear = moment().startOf('year');
    const endOfMay = moment().startOf('year').add(4, 'months').endOf('month');
    const startOfJune = moment()
      .startOf('year')
      .add(5, 'months')
      .startOf('month');

    if (today.isBefore(startOfJune)) {
      // 오늘 날짜가 5월 31일 이전이면, 작년 1월 1일부터 12월 31일까지를 반환
      return {
        corp_start_date: moment()
          .subtract(1, 'year')
          .startOf('year')
          .format('YYYYMMDD'),
        corp_end_date: moment()
          .subtract(1, 'year')
          .endOf('year')
          .format('YYYYMMDD'),
        corp_expected_date: endOfMay.format('YYYYMMDD'),
      };
    } else {
      // 그렇지 않으면, 올해 1월 1일부터 오늘까지를 반환
      return {
        corp_start_date: startOfYear.format('YYYYMMDD'),
        corp_end_date: today.format('YYYYMMDD'),
        corp_expected_date: moment()
          .add(1, 'years')
          .month(5 - 1)
          .date(31)
          .format('YYYYMMDD'),
      };
    }
  }
};

export const formatDateKrType = (date: string) => {
  return moment(date, 'YYYYMMDD').format('YYYY년 MM월 DD일');
};

export const formatCurrencyToKRW = (number: number) => {
  const formatted = new Intl.NumberFormat('ko-KR', {
    maximumFractionDigits: 0,
  }).format(number);
  return `${formatted}원`;
};

export function generateLastYearsOptions(
  targetYear: number,
  numberOfYears: number,
): {
  value: string;
  label: string;
}[] {
  const lastYearsOptions: { value: string; label: string }[] = [];

  for (let i = 0; i < numberOfYears; i++) {
    const year = targetYear - i;
    lastYearsOptions.push({ value: `${year}`, label: `${year}` });
  }

  return lastYearsOptions;
}

export function generateTxnrmStrtYm(
  targetYear: number,
  numberOfYears: number,
): [string, ...string[]] {
  const years: string[] = [];

  for (let i = 0; i < numberOfYears; i++) {
    years.push(`${targetYear - i}`);
  }

  if (years.length === 0) {
    throw new Error('numberOfYears must be at least 1');
  }

  return [years[0], ...years.slice(1)] as [string, ...string[]];
}

export const financingIdRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{6,20}$/;
export const financingPasswordRegex =
  /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}[\]:;"'<>,.?/]).{8,20}$/;

export function getFileExtension(filename: string): string {
  // Find the last occurrence of '.'
  const lastDotIndex = filename.lastIndexOf('.');

  // Check if the last dot is in a valid position to consider as an extension
  if (lastDotIndex === -1 || lastDotIndex === filename.length - 1) {
    // No valid extension found
    return '';
  } else {
    // Extract and return the extension
    return filename.substring(lastDotIndex + 1);
  }
}

export function formatFileSize(bytes: number): string {
  const kilobytes = bytes / 1024;
  const megabytes = kilobytes / 1024;

  // If the size is 1MB or over, format it as MB, otherwise as KB
  if (megabytes >= 1) {
    return `${megabytes.toFixed(2)} MB`;
  } else {
    return `${kilobytes.toFixed(2)} KB`;
  }
}

export const logoutLogic = (navigate: NavigateFunction) => {
  Cookies.remove('accessToken');
  Cookies.remove('refreshToken');
  Cookies.remove('autoLogin');
  navigate('/login', { replace: true });
};

export function distingCert(oid: string) {
  let divNm = '기타';
  const perArr = [
    '1.2.410.200005.1.1.1',
    '1.2.410.200004.5.1.1.5',
    '1.2.410.200004.5.2.1.2',
    '1.2.410.200004.5.4.1.1',
    '1.2.410.200012.1.1.1',
    '1.2.410.200005.1.1.4',
    '1.2.410.200012.1.1.101',
    '1.2.410.200004.5.2.1.7.1',
    '1.2.410.200004.5.4.1.101',
    '1.2.410.200004.5.1.1.9.2',
    '1.2.410.200004.5.2.1.7.3',
    '1.2.410.200004.5.4.1.103',
    '1.2.410.200012.1.1.105',
    '1.2.410.200012.1.1.103',
    '1.2.410.200004.5.1.1.9',
    '1.2.410.200004.5.2.1.7.1',
    '1.2.410.200004.5.4.1.101',
    '1.2.410.200012.1.1.101',
    '1.2.410.200004.5.1.1.9',
    '1.2.410.200004.5.2.1.7.2',
    '1.2.410.200004.5.4.1.102',
    '1.2.410.200012.1.1.103',
    '1.2.410.200004.5.4.1.104',
    '1.2.410.200004.5.5.1.3.1',
    '1.2.410.200004.5.5.1.4.1',
    '1.2.410.200004.5.5.1.4.2',
  ];
  const bizArr = [
    '1.2.410.200005.1.1.5',
    '1.2.410.200004.5.1.1.7',
    '1.2.410.200004.5.2.1.1',
    '1.2.410.200004.5.4.1.2',
    '1.2.410.200012.1.1.3',
    '1.2.410.200005.1.1.2',
    '1.2.410.200005.1.1.6.1',
    '1.2.410.200004.5.1.1.12.908',
    '1.2.410.200004.5.2.1.5001',
    '1.2.410.200004.5.2.1.6.257',
    '1.2.410.200005.1.1.6.8',
    '1.2.410.200005.1.1.6.3',
    '1.2.410.200005.1.1.6.5',
    '1.2.410.200005.1.1.6.4',
    '1.2.410.200005.1.1.7.1',
    '1.2.410.200004.5.5.1.2',
  ];

  if (perArr.includes(oid)) {
    divNm = '개인';
  } else if (bizArr.includes(oid)) {
    divNm = '법인';
  }

  return divNm;
}

export const isEmpty = (value: string | null | undefined): boolean => {
  return !value || value.trim().length === 0;
};

export const isNotEmpty = (value: string | null | undefined): boolean => {
  return !isEmpty(value);
};

export const isAnyEmpty = (
  ...values: Array<string | null | undefined>
): boolean => {
  return values.some(isEmpty);
};

export const isAnyNotEmpty = (
  ...values: Array<string | null | undefined>
): boolean => {
  return values.some(isNotEmpty);
};

export function getLastSegment(card_number: string): string {
  const number = String(card_number);
  if (number) {
    if (number.includes('-')) {
      const segments = number.split('-');
      return segments[segments.length - 1]
        ? `(${segments[segments.length - 1]})`
        : '';
    } else {
      return getLastFourDigits(number);
    }
  } else {
    return '';
  }
}

export function getLastFourDigits(card_number: string) {
  if (card_number && card_number.length >= 4) {
    return `(${card_number.slice(-4)})`;
  } else {
    return '';
  }
}

export function getNextFiveBusinessDays() {
  const today = moment();
  const businessDays: Moment[] = [];

  for (let i = 0; i < 5; i++) {
    // Check if today is a weekend (6 = Saturday, 0 = Sunday)
    while (today.day() === 6 || today.day() === 0) {
      // If it's a weekend, add a day until it's a weekday
      today.add(1, 'days');
    }
    // Add the business day to the array
    businessDays.push(moment(today));
    // Prepare for the next day
    today.add(1, 'days');
  }

  // Format the dates to be more readable
  // return businessDays.map((day) => day.format("YYYYMMDD"));
  return businessDays;
}

export function convertNumberToDateKR(day: number) {
  switch (day) {
    case 0:
      return '일요일';
    case 1:
      return '월요일';
    case 2:
      return '화요일';
    case 3:
      return '수요일';
    case 4:
      return '목요일';
    case 5:
      return '금요일';
    case 6:
      return '토요일';
  }
}

export function getPreviousDateStr(dateStr: string) {
  const date = moment(dateStr, 'YYYYMMDD');
  return date.subtract(1, 'days').format('YYYYMMDD');
}

export function getNextDateStr(dateStr: string) {
  const date = moment(dateStr, 'YYYYMMDD');
  return date.add(1, 'days').format('YYYYMMDD');
}

export function deliveryAndCommerceMapper(name: string) {
  let result = '';
  if (name == 'BM') {
    result = '배달의민족';
  } else if (name == 'CPE') {
    result = '쿠팡이츠';
  } else if (name == 'YO') {
    result = '요기요';
  } else if (name == 'NAVER') {
    result = '네이버';
  } else if (name == 'COUPANG') {
    result = '쿠팡';
  } else if (name === 'ETC') {
    return '기타 매출';
  }

  return result;
}

export function generateOptions(
  options: string[],
  includeAll = true,
): OptionValue[] {
  let optionArray: string[] = options;

  if (!options.includes('전체') && includeAll) {
    const all: string[] = ['전체'];
    optionArray = [...all, ...options] as string[];
  }

  return optionArray.map((item: string) => ({
    label: item,
    value: item,
  }));
}

export const selectColorCertificateIssued = (status: string) => {
  switch (status) {
    case CERTIFICATES_ISSUED_MENU.ISSUED:
      return '#3182F6';
    case CERTIFICATES_ISSUED_MENU.FAILED:
      return '#949494';
    case CERTIFICATES_ISSUED_MENU.DENIED:
      return '#F96464';
    case CERTIFICATES_ISSUED_MENU.PENDING:
      return '#8983F1';
    default:
      return '#3182F6';
  }
};

export const logoutLogicAdmin = (navigate: NavigateFunction) => {
  Cookies.remove('accessTokenAdmin');
  Cookies.remove('refreshTokenAdmin');
  Cookies.remove('autoLoginAdmin');
  navigate('/admin/login', { replace: true });
};

export const formatWithThousandSeparator = (value: string): string => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseThousandSeparatedNumber = (value: string): number => {
  return parseInt(value.replace(/,/g, ''), 10);
};

export const formatBusinuessNumber = (input: string): string => {
  // Check if the input is empty
  if (input === '') {
    return '';
  }

  // Check if the input length is exactly 10
  if (input.length !== 10) {
    throw new Error('Input must be exactly 10 digits.');
  }

  // Use a regular expression to format the string
  return input.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
};

export const formatDateToYYYYMMDD = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
};

export const formatDateToYYYYMMDDHypen = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const numberWithCommas = (value: string) => {
  // Remove existing commas
  const num = value.replace(/,/g, '');
  // Add commas for thousand separator
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
